import { DocumentType } from "../../features/Copilot/EntryGen/generatedTypes";

// Hack to ensure we match an exhaustive list of document types
const DocumentTypeMap: Record<DocumentType, 0> = {
  "SOC 2 Type 2 Report": 0,
  "Information Security Policy": 0,
  "Penetration Test Report": 0,
  "Acceptable Use Policy": 0,
  "Access Control Policy": 0,
  "Network Security Policy": 0,
  "Risk Management Policy": 0,
  "Privacy Policy": 0,
  "Software Management Policy": 0,
  "Code of Conduct": 0,
  "Encryption and Key Management Policy": 0,
  "Change Management Policy": 0,
  "Asset Management Policy": 0,
  "Vendor Management Policy": 0,
  "Human Resource Policy": 0,
  "Incident Management Policy": 0,
  "Business Continuity and Disaster Recovery Policy": 0,
  "Vulnerability and Threat Management Policy": 0,
  "Patch Management Policy": 0,
  "Data Retention and Deletion Policy": 0,
  "Data Governance Policy": 0,
  "Secure Development Lifecycle (SDLC) Policy": 0,
  "Application Management Policy": 0,
  "Application Security Policy": 0,
  "Removable Media Policy": 0,
  "Physical and Environmental Security Policy": 0,
  "Mobile Device Management Policy": 0,
  "Supply Chain Risk Management (SCRM) Policy": 0,
  "Wireless Security Policy": 0,
  "Malware Protection Policy": 0,
  "Anti-Bribery and Corruption Policy": 0,
  "Artificial Intelligence Policy": 0,
  "Interoperability and Portability Policy": 0,
  "Server Security Policy": 0,
  "Logging and Monitoring Policy": 0,
  "Security & Privacy Training and Awareness Policy": 0,
  "Password Policy": 0,
  "API Security Policy": 0,
  "Cyber Insurance Policy": 0,
};

export const DocumentTypes = Object.keys(DocumentTypeMap) as DocumentType[];
