import { Route, useNavigate } from "react-router-dom";
import AppRoutes from "../../components/AppRoutes";
import AllGenerationsRequests from "./components/AllEntryGenRequests";
import EntryGenRequestew from "./components/EntryGenRequestView";

const EntryGenContainer = (): JSX.Element => {
  const navigate = useNavigate();
  return (
    <AppRoutes>
      <Route path="/" element={<AllGenerationsRequests />} />
      <Route
        path="answers/:generationId"
        element={<EntryGenRequestew onBack={() => navigate("/entry-gen")} />}
      />
    </AppRoutes>
  );
};

export default EntryGenContainer;
