import { GoogleOAuthProvider } from "@react-oauth/google";
import * as Sentry from "@sentry/react";
import { ConfigProvider, Result, message, notification } from "antd";
import "antd/dist/reset.css";
import { clarity } from "clarity-js";
import { StrictMode, useEffect } from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import {
  RouterProvider,
  createBrowserRouter,
  createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import { beforeSentrySend } from "shared_frontend";
import TokenHandler from "shared_utils/tokenHandler";
import { initialize as initializeAnalytics } from "./analytics";
import App from "./containers/App";
import store, { RootState } from "./store";
import { mainColors } from "./utils/constants";
import { saveState } from "./utils/localStorage";
import { sentryReplay } from "./utils/sentry";

initializeAnalytics();

if (
  window.ENV_VARIABLE_SENTRY_ENABLED &&
  window.ENV_VARIABLE_SENTRY_ENABLED.length !== 0
) {
  const integrations = [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    sentryReplay,
  ];

  Sentry.init({
    dsn: window.ENV_VARIABLE_SENTRY_FRONTEND_DSN,
    autoSessionTracking: true,
    integrations,
    environment: window.ENV_VARIABLE_SENTRY_ENVIRONMENT,
    ignoreErrors: [
      "ResizeObserver loop limit exceeded",
      // Sentry docs: When using strings, partial matches will be filtered out.
      // Cloudflare docs: Error codes are separated by the first three numbers into error-code families.
      // Error code family 300*** and 600*** is a response generated by the Turnstile in situations
      // where a potential bot or unusual visitor behavior is detected.
      "[Cloudflare Turnstile] Error: 300",
      "[Cloudflare Turnstile] Error: 600",
    ],
    tracesSampleRate: 1.0,
    //we start replays manually for non-staff users
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 0.0,
    normalizeDepth: 5,
    beforeSend: beforeSentrySend,
    release: window.ENV_VARIABLE_COMMIT_SHA,
  });
}

if (
  window.ENV_VARIABLE_CLARITY_PROJECT_ID &&
  window.ENV_VARIABLE_CLARITY_PROJECT_ID.length !== 0
) {
  clarity.start({
    projectId: window.ENV_VARIABLE_CLARITY_PROJECT_ID,
    upload: "https://m.clarity.ms/collect",
    track: true,
    content: true,
  });
}

TokenHandler.loadTokens();

store.subscribe(() => {
  const { userAuth, registration, layout }: RootState = store.getState();

  // LocalStorage
  if (userAuth || registration || layout) {
    saveState({ userAuth, registration, layout });
  }

  // Sentry and Clarity
  if (userAuth?.userAuthData?.email) {
    const sessionId = crypto.randomUUID();
    const email = userAuth?.userAuthData?.email;

    Sentry.getCurrentScope()
      .setUser({
        email,
      })
      .setContext("claritySessionId", { sessionId });

    // Set custom tag for filtering out securitypal users
    const isStaff = email?.endsWith("@securitypalhq.com") ? "true" : "false";
    clarity.set("isStaff", isStaff);

    clarity.identify(email, sessionId, undefined, email);
  } else {
    Sentry.getCurrentScope().setUser(null);
  }
});

message.config({ maxCount: 1 });
notification.config({
  placement: "bottomRight",
  maxCount: 1,
});

const googleClientId = window.ENV_VARIABLE_GOOGLE_CLIENT_ID || "";
const root = createRoot(document.getElementById("root") as HTMLElement);

const baseRoute = {
  path: "*",
  element: (
    <ConfigProvider theme={{ token: { colorPrimary: mainColors.blue } }}>
      <GoogleOAuthProvider clientId={googleClientId}>
        <Sentry.ErrorBoundary
          fallback={
            <Result status="500" title="Unexpected Error. Please try again." />
          }
        >
          <App />
        </Sentry.ErrorBoundary>
      </GoogleOAuthProvider>
    </ConfigProvider>
  ),
};
const router = createBrowserRouter([baseRoute]);

root.render(
  <StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </StrictMode>,
);
