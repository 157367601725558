import { BellFilled } from "@ant-design/icons";
import { Turnstile, TurnstileInstance } from "@marsidev/react-turnstile";
import { Button, ConfigProvider, Form, Input, Popover, Typography } from "antd";
import Color from "color";
import { useEffect, useRef, useState } from "react";
import { ProfileVersionResponse } from "../../../app_frontend/src/features/AssuranceProfile/autoGeneratedTypes";
import styles from "./AssuranceProfileSubscription.module.scss";

interface AssuranceProfileSubscriptionProps {
  handleSubscribe?: (email: string, turnstileToken: string) => Promise<void>;
  profileVersion: ProfileVersionResponse;
  hasSubscribed?: boolean;
  turnstileKey?: string;
}

const AssuranceProfileSubscription: React.FC<
  AssuranceProfileSubscriptionProps
> = ({ handleSubscribe, profileVersion, hasSubscribed, turnstileKey }) => {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const accentColor =
    profileVersion.relationships.styleSettings.attributes.primaryColor;
  const companyName = profileVersion.attributes.companyName;
  const disableSubscriptionButton = !handleSubscribe;
  const turnstileRef = useRef<TurnstileInstance>();

  const resetTurnstile = () => {
    turnstileRef.current?.reset();
  };

  useEffect(() => {
    // Context for why we're adding this eventListener:
    // https://github.com/SecurityPal/security-pal/pull/12082
    window.addEventListener("focus", resetTurnstile);
    return () => {
      window.removeEventListener("focus", resetTurnstile);
    };
  }, []);

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: accentColor,
            colorPrimaryHover: Color(accentColor).alpha(0.75).string(),
            borderColorDisabled: Color(accentColor).alpha(0.25).string(),
            colorBgContainerDisabled: accentColor,
            colorTextDisabled: Color(accentColor).isLight() ? "black" : "white",
          },
        },
      }}
    >
      <Popover
        placement="bottomLeft"
        trigger="click"
        title={hasSubscribed ? "" : `Subscribe to ${companyName}'s Updates`}
        content={
          hasSubscribed ? (
            <Typography.Text>
              Please check your email inbox and confirm your subscription.
            </Typography.Text>
          ) : (
            <>
              <Typography.Paragraph>
                Keep track of updates from {companyName} — sent straight to your
                inbox.
              </Typography.Paragraph>
              <Form
                layout="vertical"
                form={form}
                onFinish={async (values: { email: string }) => {
                  setIsLoading(true);
                  try {
                    await handleSubscribe?.(
                      values.email,
                      turnstileRef.current?.getResponse() ?? "",
                    );
                  } catch (_e) {
                    //no op
                  } finally {
                    setIsLoading(false);
                    turnstileRef.current?.reset();
                  }
                }}
              >
                <Form.Item
                  label="Email"
                  name={"email"}
                  validateDebounce={1000}
                  rules={[{ required: true, type: "email" }]}
                >
                  <Input type="text" />
                </Form.Item>
                {turnstileKey && (
                  <Turnstile
                    className={styles.Turnstile}
                    ref={turnstileRef}
                    siteKey={turnstileKey}
                  />
                )}
                <Form.Item>
                  <Button
                    type="primary"
                    icon={<BellFilled />}
                    htmlType="submit"
                    loading={isLoading}
                  >
                    Subscribe to updates
                  </Button>
                </Form.Item>
              </Form>
            </>
          )
        }
      >
        <Button
          type="primary"
          disabled={disableSubscriptionButton}
          icon={<BellFilled className={styles.BellIcon} />}
        >
          Subscribe
        </Button>
      </Popover>
    </ConfigProvider>
  );
};

export default AssuranceProfileSubscription;
