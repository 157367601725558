import {
  Array,
  Boolean,
  Literal,
  Null,
  Optional,
  Record,
  Static,
  String,
  Tuple,
  Union,
} from "runtypes";
import { runtypeFromEnum } from "../../../utils/runtypes";
import {
  CompanyRT,
  ISODateStrRT,
  PartialUserRT,
  UserRT,
} from "../../API/types";
import { FileBaseRT, FileType, RequestThreadIdRT } from "./base";
import { RequestThreadEventRT } from "./events";
import { LabelRT } from "./labels";
import { QuestionnaireTaskRT } from "./tasks";

const CommentIdRT = String.withBrand("CommentId");
export type CommentId = Static<typeof CommentIdRT>;

export enum RequestThreadType {
  AIRTABLE = "airtableRequestThread",
  API = "apiRequestThread",
  GMAIL = "gmailRequestThread",
  IN_APP = "inAppRequestThread",
}
const RequestThreadTypeRT = runtypeFromEnum(RequestThreadType);

export const EmailParticipantRT = Record({
  email: String,
  firstName: String.nullable(),
  lastName: String.nullable(),
});
export type EmailParticipant = Static<typeof EmailParticipantRT>;

export const CommentFileRT = FileBaseRT.extend({
  type: Literal(FileType.commentFile),
});

export type CommentFile = Static<typeof CommentFileRT>;

export const CommentRT = Record({
  id: CommentIdRT,
  type: Literal("comment"),
  attributes: Record({
    htmlContent: String,
    createdAt: ISODateStrRT,
    editedAt: Optional(ISODateStrRT),
    isInternal: Optional(Boolean),
  }),
  relationships: Record({
    files: Array(CommentFileRT),
    createdBy: Optional(Union(PartialUserRT, UserRT)),
  }),
});
export type Comment = Static<typeof CommentRT>;

// These are in the order as they should appear in the side nav.
// This order reflects the flow of a thread.
export enum RequestThreadInbox {
  SECURITYPAL_CONCIERGE = "securitypal-concierge",
  AWAITING_RESPONSE = "awaiting-response",
  CIRRT_INBOX = "cirrt-inbox",
  AWAITING_FINAL_VERSION = "awaiting-final-version",
  //Team switched from Answer Library -> Knowledge library. Backend enum not modified
  //hence the discrepencacy here
  RETURN_SQ_FOLLOW_UP = "return-sq-follow-up",
  KL_UPDATE = "al-update",
  C_SAT_SURVEY = "c-sat-survey",
  ENTERPRISE = "enterprise",
  FOUNDATION = "foundation",
  STRATEGIC = "strategic",
}

export const RequestThreadBaseAttributesRT = Record({
  createdAt: ISODateStrRT,
  updatedAt: ISODateStrRT,
  lastMessageReceivedAt: ISODateStrRT,
  lastMessageSentAt: Optional(ISODateStrRT),
  seen: Boolean,
  bookmarked: Boolean,
  subject: String,
  participants: Array(EmailParticipantRT),
  inbox: runtypeFromEnum(RequestThreadInbox),
  isArchived: Boolean,
});

export const RequestThreadBaseRelationshipsRT = Record({
  assignedTo: Optional(PartialUserRT),
  company: Optional(CompanyRT),
  labels: Array(LabelRT),
  comments: Array(CommentRT),
  task: Optional(QuestionnaireTaskRT),
  events: Array(RequestThreadEventRT),
});

export const RequestThreadBaseRT = Record({
  type: RequestThreadTypeRT,
  id: RequestThreadIdRT,
  attributes: RequestThreadBaseAttributesRT,
  relationships: RequestThreadBaseRelationshipsRT,
});

export const InAppRequestThreadRT = RequestThreadBaseRT.extend({
  type: Literal(RequestThreadType.IN_APP),
  attributes: RequestThreadBaseAttributesRT.extend({
    externalId: Null,
    createdBy: PartialUserRT.nullable(),
  }),
  relationships: RequestThreadBaseRelationshipsRT.extend({
    task: QuestionnaireTaskRT,
  }),
});
export type InAppRequestThread = Static<typeof InAppRequestThreadRT>;

export const APIRequestThreadRT = RequestThreadBaseRT.extend({
  type: Literal(RequestThreadType.API),
  attributes: RequestThreadBaseAttributesRT.extend({
    externalId: Null,
    //Tuple here so we can enforce a single item in the array.
    participants: Tuple(EmailParticipantRT),
  }),
  relationships: RequestThreadBaseRelationshipsRT.extend({
    task: QuestionnaireTaskRT,
  }),
});
export type APIRequestThread = Static<typeof APIRequestThreadRT>;

export const AirtableRequestThreadRT = RequestThreadBaseRT.extend({
  type: Literal(RequestThreadType.AIRTABLE),
  relationships: RequestThreadBaseRelationshipsRT.extend({
    task: QuestionnaireTaskRT,
  }),
});
export type AirtableRequestThread = Static<typeof AirtableRequestThreadRT>;

export const NonStaffBaseRequestThreadRT = Record({
  type: RequestThreadTypeRT,
  id: RequestThreadIdRT,
  attributes: Record({
    subject: String,
  }),
  relationships: Record({
    task: QuestionnaireTaskRT,
    comments: Array(CommentRT),
    events: Array(RequestThreadEventRT),
    company: CompanyRT,
  }),
});

export const NonStaffAPIRequestThreadRT = NonStaffBaseRequestThreadRT.extend({
  type: Literal(RequestThreadType.API),
});

export const NonStaffInAppRequestThreadRT = NonStaffBaseRequestThreadRT.extend({
  type: Literal(RequestThreadType.IN_APP),
});

export const NonStaffAirtableRequestThreadRT =
  NonStaffBaseRequestThreadRT.extend({
    type: Literal(RequestThreadType.AIRTABLE),
  });
