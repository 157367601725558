import { AuditOutlined, EditOutlined } from "@ant-design/icons";
import { AppProps, Button, ModalProps } from "antd";
import { useState } from "react";
import AssuranceProfileCard from "../AssuranceProfileCard";
import CardHeader from "../CardHeader";
import SanitizedHTML from "../SanitizedHTML/SanitizedHTML";
import styles from "./Overview.module.scss";
interface OverviewProps extends AppProps {
  overview?: string | null;
  EditModal?: React.FC<ModalProps>;
}

const Overview = ({
  overview,
  EditModal,
  ...rest
}: OverviewProps): JSX.Element => {
  const [editModalOpen, setEditModalOpen] = useState(false);
  return (
    <>
      <AssuranceProfileCard className={styles.Card} {...rest}>
        <CardHeader
          icon={<AuditOutlined />}
          title="Overview"
          titleLevel={2}
          editButton={
            EditModal && (
              <Button
                onClick={() => setEditModalOpen(true)}
                icon={<EditOutlined />}
              />
            )
          }
        />
        {overview && (
          <SanitizedHTML html={overview} className="TileText" breakWord />
        )}
      </AssuranceProfileCard>
      {EditModal && (
        <EditModal
          open={editModalOpen}
          onCancel={() => setEditModalOpen(false)}
          onOk={() => setEditModalOpen(false)}
        />
      )}
    </>
  );
};

export default Overview;
