import { skipToken } from "@reduxjs/toolkit/query";
import { Button, Table } from "antd";
import { useState } from "react";
import { useParams } from "react-router-dom";
import AppHeader from "../../../components/AppHeader";
import { useGetAnswersForEntryGenRequestQuery } from "../../../features/API/entryGen";
import { Excerpt } from "../../../features/Copilot/EntryGen/generatedTypes";
import styles from "./EntryGenRequestView.module.css";

type EntryGenRequestViewProps = {
  onBack: () => void;
};

const ENTRY_GEN_PAGE_SIZE = 10;

const EntryGenRequestView = (props: EntryGenRequestViewProps) => {
  const { generationId } = useParams();
  const { onBack } = props;
  const [pageNumber, setPageNumber] = useState(1);

  const { data: answersResult, isLoading } =
    useGetAnswersForEntryGenRequestQuery(
      generationId
        ? {
            generationId,
            limit: ENTRY_GEN_PAGE_SIZE,
            offset: ENTRY_GEN_PAGE_SIZE * (pageNumber - 1),
          }
        : skipToken,
    );

  const columns = [
    { title: "Question", key: "question", dataIndex: ["question"] },
    { title: "Answer", key: "answer", dataIndex: ["answer"] },
    {
      title: "Sources",
      key: "sources",
      dataIndex: ["sources"],
      render: (sources: Excerpt[]) => {
        return sources.map((source) => {
          return (
            <div key={source.text}>
              {source.text} (Page: {source.page})
            </div>
          );
        });
      },
    },
  ];

  return (
    <>
      <div className={styles.HeaderRow}>
        <Button onClick={onBack}> ← </Button>
        <AppHeader title="Generation Request" />
      </div>

      <div>
        <b>Status:</b> {status || "Unknown"}
      </div>
      <Table
        columns={columns}
        dataSource={answersResult?.data?.answers || []}
        loading={isLoading}
        pagination={{
          current: pageNumber,
          total: answersResult?.meta?.totalCount || 0,
        }}
        onChange={(pagination) => {
          if (pagination.current) {
            setPageNumber(pagination.current);
          }
        }}
      />
    </>
  );
};

export default EntryGenRequestView;
