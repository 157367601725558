import { UploadFile } from "antd/lib/upload/interface";
import { Record, Static, String } from "runtypes";
import { CreateTaskFormInstanceData } from "../../../containers/MissionControlContainer/components/TaskProvisioner/Create/CreateTaskForm";
import {
  CompanyId,
  FileUploadConfig,
  ISODateStr,
  UserId,
} from "../../API/types";
import { RequestThreadId } from "./base";
import { RequestThreadInbox } from "./base_threads";
import {
  GlobalMessageId,
  GmailDraftMessageId,
  GmailMessageId,
  ScheduledGmailDraftMessageRT,
} from "./gmail";
import { LabelId } from "./labels";
import { QuestionnaireTaskId } from "./tasks";

export type UpsertDraftParams = {
  draftId?: GmailDraftMessageId | null;
  gmailMessageId?: GmailMessageId | null;
  globalMessageId?: GlobalMessageId | null;
  body?: string | null;
  subject?: string;
  to?: string[];
  cc?: string[];
  bcc?: string[];
};

export type CreateDraftParams = UpsertDraftParams & {
  sendImmediately?: boolean;
  gmailMessageId?: GmailMessageId;
  files?: UploadFile[];
};

export type GmailDraftPostProcessParams = {
  gcsFileName: string;
  fileName: string;
  contentType?: string;
};

export type PostCreateDraftParams = UpsertDraftParams & {
  sendImmediately?: boolean;
  gmailMessageId?: GmailMessageId;
  files?: GmailDraftPostProcessParams[];
  companyId: string;
};

export const ScheduleSendDraftResponseRT = Record({
  taskName: String,
  draft: ScheduledGmailDraftMessageRT,
});

export type ScheduleSendDraftResponse = Static<
  typeof ScheduleSendDraftResponseRT
>;

export enum GetThreadsSearchOrdering {
  lastReceived = "-last_message_received_at",
  lastSent = "-last_message_sent_at",
}
export interface GetThreadsQueryParams {
  searchQuery?: RequestThreadQuery;
  ordering?: GetThreadsSearchOrdering;
  limit?: number;
  offset?: number;
}

export interface RequestThreadQuery {
  generalSearch?: string;
  isDraft?: boolean;
  sent?: boolean;
  seen?: boolean;
  bookmarked?: boolean;
  subject?: string;
  labelIds?: LabelId[];
  assignedUser?: UserId;
  unassigned?: boolean;
  companyId?: CompanyId;
  participants?: string[];
  inbox?: RequestThreadInbox;
  mentionedUserIds?: UserId[];
}

interface Sender {
  name: string;
  email: string;
}
export interface PartialAcceptQuestionnaireParams {
  overrideDueDate: boolean;
  dueDate?: Date;
  internalAcceptanceNotes?: string;
  externalAcceptanceNotesRaw?: string;
  questionnaireType: string[];
  expediteRequestGranted?: boolean;
}
export interface AcceptTaskExistingThreadParams
  extends CreateTaskFormInstanceData,
    PartialAcceptQuestionnaireParams {
  threadId: RequestThreadId;
  existingFileIds?: string[];
  sender: Sender;
  sentAt: ISODateStr;
}

export interface AcceptQuestionnaireParams
  extends PartialAcceptQuestionnaireParams {
  questionnaireTaskId: QuestionnaireTaskId;
  portalFiles?: UploadFile[];
}

export interface BaseReturnCompletedQuestionnaireParams {
  questionnaireTaskId: QuestionnaireTaskId;
  threadId: RequestThreadId;
  noFilesToReturn?: boolean;
  completionNotes?: {
    html: string;
    mentionedUserIds: number[];
  };
}

export interface ReturnCompletedQuestionnaireParams
  extends BaseReturnCompletedQuestionnaireParams {
  files?: File[];
}

export interface PostProcessReturnCompletedQuestionnaireParams
  extends BaseReturnCompletedQuestionnaireParams {
  fileUploadConfigs?: FileUploadConfig[];
  companyId: string;
}

export interface ParsedProvisioningResponse {
  customerName?: string;
  companyId?: CompanyId;
  requestReceivedAt: ISODateStr;
  senderName?: string;
  senderEmail: string;
  threadId: RequestThreadId;
}
