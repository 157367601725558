import { Form, Input, Modal, Select, message } from "antd";
import { useForm } from "antd/es/form/Form";
import { RcFile } from "antd/lib/upload";
import { useState } from "react";
import { ProductSelector } from "../../../components/ActiveProductSelector";
import RequiredFormItem from "../../../components/RequiredFormItem";
import UploadDragger from "../../../components/UploadDragger";
import { useNewEntryGenRequestMutation } from "../../../features/API/entryGen";
import { EntryGenParams } from "../../../features/Copilot/EntryGen/generatedTypes";
import { useMessageForMutationResultHook } from "../../../utils/hooks";
import { DocumentTypes } from "../documentTypes";

interface NewEntryGenRequestModalProps {
  open: boolean;
  onCancel: () => void;
  onSuccess: () => void;
}

const NewEntryGenRequestModal = ({
  open,
  onCancel,
  onSuccess,
}: NewEntryGenRequestModalProps): JSX.Element => {
  const [form] = useForm<EntryGenParams>();

  const [entryGen, { error, isLoading, status }] =
    useNewEntryGenRequestMutation();
  useMessageForMutationResultHook(
    status,
    error,
    "Knowledge Base Entry Generation Requested",
    "Error requesting Knowledge Base Entry Generation",
  );

  const product = Form.useWatch("product", form);
  const [pdfFile, setPdfFile] = useState<RcFile | undefined>(undefined);

  const onSelectFile = async (file?: RcFile) => {
    setPdfFile(file);
  };

  return (
    <Modal
      open={open}
      title="Generate Answer Library Entries from Document"
      confirmLoading={isLoading}
      destroyOnClose
      onCancel={onCancel}
      onOk={async () => {
        const { documentType } = await form.validateFields();
        if (!pdfFile || !documentType) {
          message.error("Must include file and document type");
          return;
        }
        const formData = new FormData();
        formData.append("file", pdfFile);
        formData.append("documentType", documentType);
        formData.append("libraryId", product.id.toString());
        await entryGen(formData);
        form.resetFields();
        onSuccess();
      }}
    >
      <Form layout="vertical" form={form}>
        <RequiredFormItem label="Product" name="product">
          <ProductSelector />
        </RequiredFormItem>
        <RequiredFormItem label="Document Type" name="documentType">
          <Select
            placeholder="Select a document type"
            options={DocumentTypes.map((dT) => ({ label: dT, value: dT }))}
          />
        </RequiredFormItem>
        <UploadDragger
          onSetFile={onSelectFile}
          acceptedFiles=".pdf"
          mainText="PDF File to Answer Library Entries From"
          subtitleText={<li>Click or drag to upload.</li>}
          hideButton={true}
        />
      </Form>
    </Modal>
  );
};

export default NewEntryGenRequestModal;
