import { Cascader, Select } from "antd";
import clsx from "clsx";
import { SingleValueType } from "rc-cascader/lib/Cascader";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { CompanyId, Product, ProductId } from "../../features/API/types";
import {
  MULTIPLE_LIBRARY_IDS_QUERY_KEY,
  getProductParams,
} from "../../features/KnowledgeLibrary/utils";
import { selectSortedProducts, setActiveProduct } from "../../features/Layout";
import { groupBy } from "../../utils/helper";
import { useActiveProduct, useAppSelector } from "../../utils/hooks";
import styles from "./ProductSelector.module.css";

interface CompanyOption {
  value: CompanyId | string;
  label: string;
  children: ProductOption[];
}

interface ProductOption {
  value: ProductId;
  label: string;
}
interface ProductSelectorProps {
  size?: "small" | "middle" | "large";
  className?: string;
  onChange?: (product: Product | undefined) => void;
  value?: Product;
}

export const ProductSelector = ({
  size,
  className,
  onChange,
  value,
}: ProductSelectorProps): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams();
  const productIds = getProductParams(searchParams);
  const parsedProductIdParam = productIds?.[0];

  const products: Product[] = useAppSelector(selectSortedProducts);

  useEffect(() => {
    if (parsedProductIdParam) {
      const product = products.find((p) => p.id === parsedProductIdParam);
      if (product) {
        onChange?.(product);
      }
    }
  }, [parsedProductIdParam, onChange, products]);

  const productsToCascaderOptions = (products: Product[]): CompanyOption[] => {
    const groupedByCompany = groupBy(
      products,
      (p) => p.relationships.company.data.id,
    );
    return (
      Object.values(groupedByCompany)
        .map((products) => {
          const company = products[0].relationships.company.data;
          const children: ProductOption[] = products
            .map((el) => ({
              value: el.id,
              label: el.attributes.productName,
            }))
            //sort alphabetically
            .sort((a, b) =>
              a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1,
            );
          return {
            value: company.id,
            label: company.attributes.displayName,
            children,
          };
        })
        //sort alphabetically
        .sort((a, b) =>
          a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1,
        )
    );
  };

  const selectedValueCascaderArray = (product: Product | null): string[] =>
    product
      ? [product.relationships.company.data.id, product.attributes.productName]
      : [];

  const cascaderOptions = productsToCascaderOptions(products);

  return cascaderOptions.length > 1 ? (
    <Cascader<CompanyOption>
      onChange={(value: SingleValueType) => {
        if (onChange) {
          const castValues = value as number[];
          const productId = castValues.pop() as ProductId;
          const product = products.find((p) => p.id === productId);
          onChange(product);
          if (parsedProductIdParam) {
            setSearchParams((prev) => {
              if (product) {
                prev.set(MULTIPLE_LIBRARY_IDS_QUERY_KEY, product.id.toString());
              } else {
                prev.delete(MULTIPLE_LIBRARY_IDS_QUERY_KEY);
              }
              return prev;
            });
          }
        }
      }}
      allowClear={false}
      placeholder="Select library"
      showSearch
      aria-label="Library selector"
      size={size}
      value={value ? selectedValueCascaderArray(value) : undefined}
      className={clsx(className, styles.ProductSelector)}
      options={cascaderOptions}
      expandTrigger="hover"
    />
  ) : (
    <Select<ProductId, ProductOption>
      placeholder="Select library"
      showSearch
      onSelect={(value: ProductId) => {
        if (onChange) {
          const product = products.find((p) => p.id === value);
          onChange(product);
          if (parsedProductIdParam) {
            setSearchParams((prev) => {
              if (product) {
                prev.set(MULTIPLE_LIBRARY_IDS_QUERY_KEY, product.id.toString());
              } else {
                prev.delete(MULTIPLE_LIBRARY_IDS_QUERY_KEY);
              }
              return prev;
            });
          }
        }
      }}
      className={clsx(className, styles.ProductSelector)}
      size={size}
      value={value?.id}
      aria-label="Library selector"
    >
      {products.map(({ id, attributes }) => (
        <Select.Option key={id} value={id}>
          {attributes.productName}
        </Select.Option>
      ))}
    </Select>
  );
};

type ActiveProductSelectorProps = Omit<ProductSelectorProps, "onChange">;

const ActiveProductSelector = ({
  size,
  className,
}: ActiveProductSelectorProps): JSX.Element => {
  const dispatch = useDispatch();
  const activeProduct = useActiveProduct();

  const dispatchNewProduct = (product?: Product) => {
    if (product) {
      dispatch(setActiveProduct(product));
    }
  };

  return (
    <ProductSelector
      className={className}
      size={size}
      onChange={dispatchNewProduct}
      value={activeProduct || undefined}
    />
  );
};

export const InlineProductSelector = (): JSX.Element => {
  const products = useAppSelector(selectSortedProducts);
  if (products.length <= 1) {
    return <></>;
  }

  return (
    <div className={styles.InlineProductSelector}>
      <div className={styles.InlineProductSelector_label}>Library:</div>
      <ActiveProductSelector size="large" />
    </div>
  );
};

export default ActiveProductSelector;
