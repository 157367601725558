import { EditOutlined } from "@ant-design/icons";
import { captureException } from "@sentry/react";
import {
  Button,
  ConfigProvider,
  ModalProps,
  Result,
  Typography,
  message,
} from "antd";
import Color from "color";
import { useState } from "react";
import { ProfileVersionResponse } from "../../../app_frontend/src/features/AssuranceProfile/autoGeneratedTypes";
import AssuranceProfileCard from "../AssuranceProfileCard";
import { EditRequestInfoSettingsModalProps } from "../AssuranceProfileWrapper";
import RequestInfoSubtitle from "../RequestInfoSubtitle";
import { checkNdaAlreadySigned } from "../RequestInfoV2/utils";
import { NdaType } from "../types/assuranceProfile";
import RequestInfoForm, { RequestInfoFormParams } from "./Form";
import IroncladInlineNda, { TemplateRenderData } from "./IroncladNda";
import styles from "./RequestInfoForm.module.scss";

export interface RequestInfoProps {
  profileVersion: ProfileVersionResponse;
  disabled: boolean;
  handleSubmit?: (
    params: RequestInfoFormParams,
    turnstileToken: string,
  ) => Promise<void>;
  EditModal?: React.FC<EditRequestInfoSettingsModalProps>;
  turnstileKey?: string;
  EditSubtitleModal?: React.FC<ModalProps>;
  EditInfoTitleModal?: React.FC<ModalProps>;
  requestInfoFormRef?: React.RefObject<HTMLDivElement>;
  ironcladNdaCheckEnabled?: boolean;
}

const RequestInfo: React.FC<RequestInfoProps> = ({
  profileVersion,
  disabled,
  handleSubmit,
  EditModal,
  turnstileKey,
  EditSubtitleModal,
  EditInfoTitleModal,
  requestInfoFormRef,
  ironcladNdaCheckEnabled,
}: RequestInfoProps): JSX.Element => {
  const [editModalOpen, setEditModalOpen] = useState(false);

  const [editInfoTitleModalOpen, setEditInfoTitleModalOpen] = useState(false);
  const [ironcladSubmitLoading, setIroncladSubmitLoading] = useState(false);
  // Since ironclad clickwrap component is not reactive, we don't allow submitting form twice
  const [ironcladRequestSubmitted, setIroncladRequestSubmitted] =
    useState(false);
  const [formValues, setFormValues] = useState<RequestInfoFormParams>();
  const [turnstileToken, setTurnstileToken] = useState<string>();

  const accentColor =
    profileVersion.relationships.styleSettings.attributes.primaryColor;
  const nda = profileVersion.relationships.nda;
  const [ironcladTemplateRenderData, setIroncladTemplateRenderData] =
    useState<TemplateRenderData>();
  const [ironcladSubmitDisabled, setIroncladSubmitDisabled] = useState(true);

  const requestSettings =
    profileVersion.relationships.requestInformationSettings.attributes;

  const showIroncladNda = nda?.type === NdaType.IRONCLAD;

  return (
    <AssuranceProfileCard>
      <div className={styles.InfoTitleContainer}>
        <div ref={requestInfoFormRef}>
          <div className={styles.InfoTitle}></div>

          <Typography.Title level={2} className="RequestInfoTitle TileText">
            {requestSettings.title}
          </Typography.Title>
          {EditInfoTitleModal && (
            <Button
              onClick={() => setEditInfoTitleModalOpen(true)}
              size="small"
              icon={<EditOutlined />}
              type="text"
            />
          )}
        </div>
        {EditModal && (
          <Button
            icon={<EditOutlined />}
            onClick={() => setEditModalOpen(true)}
          >
            Edit form settings
          </Button>
        )}
      </div>

      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: accentColor,
              colorPrimaryHover: Color(accentColor).alpha(0.75).string(),
            },
            Checkbox: {
              colorPrimary: accentColor,
              colorPrimaryHover: Color(accentColor).alpha(0.75).string(),
            },
          },
        }}
      >
        <RequestInfoSubtitle
          subtitle={requestSettings.subtitle}
          EditModal={EditSubtitleModal}
        />
        {showIroncladNda && ironcladTemplateRenderData ? (
          ironcladRequestSubmitted ? (
            <Result
              status={"success"}
              title="Your request has been submitted."
            />
          ) : (
            <>
              <IroncladInlineNda
                nda={nda}
                templateRenderData={ironcladTemplateRenderData}
                onAllChecked={(checked) => setIroncladSubmitDisabled(!checked)}
              />
              <Button
                type="primary"
                loading={ironcladSubmitLoading}
                disabled={ironcladSubmitDisabled}
                onClick={async () => {
                  if (!formValues || !handleSubmit || !turnstileToken) {
                    captureException(new Error("Missing required params"));
                    return;
                  }
                  setIroncladSubmitLoading(true);
                  if (nda?.type === NdaType.IRONCLAD) {
                    if (!window._ps) {
                      captureException("Ironclad NDA not configured.");
                      return;
                    }
                    window._ps(nda.attributes.groupKey + ":send", "agreed", {
                      //temporarily allow sending events
                      disable_sending: false,
                    });
                  }
                  try {
                    await handleSubmit(formValues, turnstileToken);
                    setIroncladRequestSubmitted(true);
                  } catch (_) {
                    message.error(
                      "There was an error submitting your request.",
                    );
                  } finally {
                    setIroncladSubmitLoading(false);
                  }
                }}
              >
                Submit Request
              </Button>
            </>
          )
        ) : (
          <RequestInfoForm
            profileVersion={profileVersion}
            turnstileKey={turnstileKey}
            disabled={disabled}
            onFinish={async (values, turnstileToken) => {
              if (!handleSubmit) {
                captureException(new Error("No handleSubmit"));
                return;
              }
              if (showIroncladNda) {
                if (ironcladNdaCheckEnabled) {
                  let alreadySigned = false;
                  try {
                    alreadySigned = await checkNdaAlreadySigned(
                      values.email,
                      nda,
                    );
                  } catch (e) {
                    captureException(e);

                    // if nda check fails, fallback to presenting the nda
                    setFormValues(values);
                    setTurnstileToken(turnstileToken);
                    setIroncladTemplateRenderData({
                      firstName: values.firstName,
                      lastName: values.lastName,
                      email: values.email,
                      companyName: values.companyName,
                    });
                    return;
                  }

                  if (alreadySigned) {
                    await handleSubmit(
                      { ...values, ndaOnFile: true },
                      turnstileToken,
                    );
                    setIroncladRequestSubmitted(true);
                    return;
                  }
                }

                setFormValues(values);
                setTurnstileToken(turnstileToken);
                setIroncladTemplateRenderData({
                  firstName: values.firstName,
                  lastName: values.lastName,
                  email: values.email,
                  companyName: values.companyName,
                });
              } else {
                if (!handleSubmit) {
                  captureException(new Error("No handleSubmit"));
                  return;
                }
                await handleSubmit(values, turnstileToken);
              }
            }}
          />
        )}
      </ConfigProvider>
      {EditInfoTitleModal && (
        <EditInfoTitleModal
          open={editInfoTitleModalOpen}
          onOk={() => setEditInfoTitleModalOpen(false)}
          onCancel={() => setEditInfoTitleModalOpen(false)}
        />
      )}
      {EditModal && (
        <EditModal
          profileVersionId={profileVersion.id}
          open={editModalOpen}
          onOk={() => setEditModalOpen(false)}
          onCancel={() => setEditModalOpen(false)}
        />
      )}
    </AssuranceProfileCard>
  );
};

export default RequestInfo;
