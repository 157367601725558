import {
  Array,
  Boolean,
  Literal,
  Number,
  Record,
  Static,
  String,
  Union,
} from "runtypes";

import {
  CompanyIdRT,
  SimpleUserRT,
} from "../../../app_frontend/src/features/API/types";
import { runtypeFromEnum } from "../../../app_frontend/src/utils/runtypes";

export const MetaRT = Record({
  totalCount: Number,
  offset: Number,
});
export type Meta = Static<typeof MetaRT>;

const ISODateStrRT = String.withBrand("ISODateStr");
export type ISODateStr = Static<typeof ISODateStrRT>;

export const ProfileIdRT = String.withBrand("ProfileId");
export type ProfileId = Static<typeof ProfileIdRT>;

export const ProfileVersionIdRT = String.withBrand("ProfileVersionId");
export type ProfileVersionId = Static<typeof ProfileVersionIdRT>;

const CertificationIdRT = String.withBrand("CertificationId");
export type CertificationId = Static<typeof CertificationIdRT>;

const CertificationSelectionIdRT = String.withBrand("CertificationSelectionId");
export type CertificationSelectionId = Static<
  typeof CertificationSelectionIdRT
>;
const SubprocessorIdRT = String.withBrand("SubprocessorId");
export type SubprocessorId = Static<typeof SubprocessorIdRT>;

const CustomerAssuranceDocumentIdRT = String.withBrand(
  "CustomerAssuranceDocumentId",
);
export type CustomerAssuranceDocumentId = Static<
  typeof CustomerAssuranceDocumentIdRT
>;

const PrivateDataRoomLinkIdRT = String.withBrand("PrivateDataRoomLinkId");
export type PrivateDataRoomLinkId = Static<typeof PrivateDataRoomLinkIdRT>;

const CustomerAssuranceNDAIdRT = String.withBrand("CustomerAssuranceNDAId");
export type CustomerAssuranceNDAId = Static<typeof CustomerAssuranceNDAIdRT>;

export const NdaProviderTypeRT = Union(
  Literal("docusign"),
  Literal("file"),
  Literal("ironclad"),
);
export type NdaProviderType = Static<typeof NdaProviderTypeRT>;

const CustomerAssuranceFileIdRT = String.withBrand("CustomerAssuranceFileId");
export type CustomerAssuranceFileId = Static<typeof CustomerAssuranceFileIdRT>;

export const TileIdRT = String.withBrand("TileId");
export type TileId = Static<typeof TileIdRT>;

export const TileItemIdRT = String.withBrand("TileItemId");
export type TileItemId = Static<typeof TileItemIdRT>;

export const AssuranceProfileUpdateIdRT = String.withBrand(
  "AssuranceProfileUpdate",
);
export type AssuranceProfileId = Static<typeof AssuranceProfileUpdateIdRT>;

const CustomerAssuranceFileRT = Record({
  id: CustomerAssuranceFileIdRT,
  type: Literal("customerAssuranceFile"),
  attributes: Record({
    fileName: String,
    contentType: String,
    publicUrl: String,
  }),
});
export const CertificationRT = Record({
  id: CertificationIdRT,
  attributes: Record({
    name: String,
    logoUrl: String,
    isGlobal: Boolean,
  }),
});
export type Certification = Static<typeof CertificationRT>;

const CertificationSelectionStatusRT = Union(
  Literal("completed"),
  Literal("inProgress"),
);

export type CertificationSelectionStatus = Static<
  typeof CertificationSelectionStatusRT
>;

export const CertificationSelectionRT = Record({
  id: CertificationSelectionIdRT,
  attributes: Record({
    status: CertificationSelectionStatusRT,
  }),
  relationships: Record({
    certification: CertificationRT,
  }),
});
export type CertificationSelection = Static<typeof CertificationSelectionRT>;

export const DocumentGroupIdRT = String.withBrand("DocumentGroupId");
export type DocumentGroupId = Static<typeof DocumentGroupIdRT>;

export const DocumentGroupRT = Record({
  id: DocumentGroupIdRT,
  type: Literal("customerAssuranceDocumentGroup"),
  attributes: Record({
    title: String,
    description: String.nullable(),
  }),
});
export type DocumentGroup = Static<typeof DocumentGroupRT>;

const DocumentFileRT = CustomerAssuranceFileRT.omit("type").extend({
  type: Literal("customerAssuranceDocumentFile"),
});

export const CustomerAssuranceDocumentRT = Record({
  id: CustomerAssuranceDocumentIdRT,
  type: Literal("customerAssuranceDocument"),
  attributes: Record({
    profileVersionId: ProfileVersionIdRT,
    documentFileId: CustomerAssuranceFileIdRT,
    title: String,
    description: String.nullable(),
    isPublic: Boolean,
    isThirdPartyAttested: Boolean,
    createdAt: ISODateStrRT,
    updatedAt: ISODateStrRT,
    reviewCycle: Number.nullable(),
    reviewedAt: ISODateStrRT.nullable().optional(),
    rank: String.nullable(),
  }),
  relationships: Record({
    file: DocumentFileRT,
    group: DocumentGroupRT.nullable(),
    reviewedBy: SimpleUserRT.nullable().optional(),
  }),
});

export const ApproveInformationRequestRT = Record({
  envelopeId: String,
});
export type CustomerAssuranceDocument = Static<
  typeof CustomerAssuranceDocumentRT
>;

export const DocumentGroupWithDocumentsRT = DocumentGroupRT.extend({
  documents: Array(CustomerAssuranceDocumentRT),
});

export type DocumentGroupWithDocuments = Static<
  typeof DocumentGroupWithDocumentsRT
>;

const NDAFileRT = CustomerAssuranceFileRT.omit("type").extend({
  type: Literal("customerAssuranceNDAFile"),
});

const NdaAttributes = Record({
  profileVersionId: ProfileVersionIdRT,
});

export enum NdaType {
  FILE = "fileBasedNda",
  DOCUSIGN = "docusignNda",
  IRONCLAD = "ironcladNda",
}

const NdaBaseRT = Record({
  id: CustomerAssuranceNDAIdRT,
  type: runtypeFromEnum(NdaType),
  attributes: NdaAttributes,
});

export const FileBasedNdaRT = NdaBaseRT.extend({
  type: Literal(NdaType.FILE),
  attributes: NdaAttributes.extend({
    isDownloadable: Boolean,
    ndaDisclaimer: String.nullable(),
  }),
  relationships: Record({
    file: NDAFileRT,
  }),
});

export type FileBasedNda = Static<typeof FileBasedNdaRT>;

export const DocusignNdaRT = NdaBaseRT.extend({
  type: Literal(NdaType.DOCUSIGN),
  attributes: NdaAttributes.extend({
    docusignTemplateId: String.optional().nullable(),
    docusignUserId: String,
    docusignAccountId: String,
    docusignBaseUri: String,
  }),
});
export type DocusignNda = Static<typeof DocusignNdaRT>;
export const IroncladNdaRT = NdaBaseRT.extend({
  type: Literal(NdaType.IRONCLAD),
  attributes: NdaAttributes.extend({
    siteAccessId: String,
    groupKey: String,
    snapshotLocationKey: String.nullable(),
  }),
});

export type IroncladNda = Static<typeof IroncladNdaRT>;

export const NdaRT = Union(FileBasedNdaRT, DocusignNdaRT, IroncladNdaRT);

export type Nda = Static<typeof NdaRT>;

export const TileItemRT = Record({
  id: TileItemIdRT,
  type: Literal("customerAssuranceTileItem"),
  attributes: Record({
    tileId: TileIdRT,
    status: Union(Literal("completed"), Literal("inProgress")).nullable(),
    type: String,
    title: String,
    content: String,
    textValue: String.nullable(),
    rank: String,
    createdAt: ISODateStrRT,
    updatedAt: ISODateStrRT,
  }),
});
export type TileItem = Static<typeof TileItemRT>;

export const TileRT = Record({
  id: TileIdRT,
  attributes: Record({
    profileVersionId: ProfileVersionIdRT,
    header: String.nullable(),
    icon: String,
    rank: String,
  }),
  relationships: Record({
    tileItems: Array(TileItemRT),
  }),
});
export type Tile = Static<typeof TileRT>;

export const AssuranceProfileUpdateRT = Record({
  id: AssuranceProfileUpdateIdRT,
  type: Literal("customerAssuranceUpdate"),
  attributes: Record({
    profileVersionId: ProfileVersionIdRT,
    header: String,
    body: String,
    publishedAt: ISODateStrRT,
  }),
});

export type AssuranceProfileUpdate = Static<typeof AssuranceProfileUpdateRT>;
const SubprocessorFileRT = CustomerAssuranceFileRT.omit("type").extend({
  type: Literal("customerAssuranceSubprocessorFile"),
});
const FaviconFileRT = CustomerAssuranceFileRT.omit("type").extend({
  type: Literal("customerAssuranceFaviconFile"),
});
export type FaviconFile = Static<typeof FaviconFileRT>;

const EmailLogoFileRT = CustomerAssuranceFileRT.omit("type").extend({
  type: Literal("customerAssuranceEmailLogoFile"),
});
export type EmailLogoFile = Static<typeof EmailLogoFileRT>;

const LogoFileRT = CustomerAssuranceFileRT.omit("type").extend({
  type: Literal("customerAssuranceLogoFile"),
});

export const SubprocessorRT = Record({
  id: SubprocessorIdRT,
  type: Literal("customerAssuranceSubprocessor"),
  attributes: Record({
    header: String.nullable(),
    description: String,
  }),
  relationships: Record({
    file: SubprocessorFileRT.nullable(),
  }),
});

export type Subprocessor = Static<typeof SubprocessorRT>;

export const StyleSettingsRT = Record({
  id: String,
  type: Literal("customerAssuranceStyleSettings"),
  attributes: Record({
    primaryColor: String,
    backgroundColor: String,
    headerColor: String,
    titleColor: String,
    textColor: String,
    font: String,
    css: String.nullable(),
  }),
});
export type StyleSettings = Static<typeof StyleSettingsRT>;

export const RequestInformationSettingsRT = Record({
  id: String,
  type: Literal("customerAssuranceRequestInformationSettings"),
  attributes: Record({
    recipientEmailAddresses: Array(String),
    autoApproveEmailDomains: Array(String),
    autoApproveEmails: Array(String),
    autoRejectEmailDomains: Array(String),
    privacyPolicyUrl: String.nullable(),
    termsOfServiceUrl: String.nullable(),
    showCustomQuestionnaireCheckbox: Boolean,
    showSecurityPalPrivacyPolicy: Boolean,
    showSecurityPalTermsOfService: Boolean,
    showForm: Boolean,
    subtitle: String.nullable(),
    title: String,
    enableCustomSecurityReview: Boolean,
    customSecurityReviewEmail: String.nullable(),
  }),
});
export type RequestInformationSettings = Static<
  typeof RequestInformationSettingsRT
>;

export const AuthorizationConsentURLRT = String;
export type AuthorizationConsentURL = Static<typeof AuthorizationConsentURLRT>;

export const LogoRT = Record({
  id: String,
  type: Literal("customerAssuranceLogo"),
  attributes: Record({
    homepageUrl: String,
  }),
  relationships: Record({
    logoFile: LogoFileRT,
  }),
});
export type Logo = Static<typeof LogoRT>;

export const ProfileVersionBaseRT = Record({
  id: ProfileVersionIdRT,
  type: Literal("customerAssuranceProfileVersion"),
  attributes: Record({
    versionId: Number,
    profileId: ProfileIdRT,
    companyName: String,
    companyId: CompanyIdRT,
    name: String.nullable(),
    title: String.nullable(),
    overview: String.nullable(),
    publishedAt: ISODateStrRT.nullable(),
    updatedAt: ISODateStrRT,
    isInUse: Boolean,
    htmlHeadTitle: String.nullable(),
    htmlHeadDescription: String.nullable(),
    htmlSnippet: String.nullable(),
    isRedesignedVersion: Boolean,
  }),
});

export const PartialProfileVersionRT = ProfileVersionBaseRT.extend({
  relationships: Record({
    documents: Array(CustomerAssuranceDocumentRT),
    nda: NdaRT.nullable(),
  }).nullable(),
});

export type PartialProfileVersion = Static<typeof PartialProfileVersionRT>;

export const ProfileVersionRT = ProfileVersionBaseRT.extend({
  relationships: Record({
    certificationSelections: Array(CertificationSelectionRT),
    documents: Array(CustomerAssuranceDocumentRT),
    tiles: Array(TileRT),
    assuranceProfileUpdates: Array(AssuranceProfileUpdateRT),
    subprocessors: Array(SubprocessorRT),
    requestInformationSettings: RequestInformationSettingsRT,
    styleSettings: StyleSettingsRT,
    logo: LogoRT.nullable(),
    faviconFile: FaviconFileRT.nullable(),
    emailLogoFile: EmailLogoFileRT.nullable(),
    nda: NdaRT.nullable(),
  }),
});
export type ProfileVersion = Static<typeof ProfileVersionRT>;

export const DocusignTemplateRT = Record({
  templateId: String,
  templateName: String,
});

export type DocusignTemplate = Static<typeof DocusignTemplateRT>;

export const ProfileRT = Record({
  id: ProfileIdRT,
  type: Literal("customerAssuranceProfile"),
  attributes: Record({
    companyId: CompanyIdRT,
    name: String,
    subdomain: String.nullable(),
    isPublic: Boolean,
    domain: String.optional().nullable(),
    createdAt: ISODateStrRT,
    updatedAt: ISODateStrRT,
    versionUpdatedAt: ISODateStrRT,
  }),
  relationships: Record({
    activeProfileVersion: PartialProfileVersionRT.nullable(),
  }).nullable(),
});

export type Profile = Static<typeof ProfileRT>;

const RequireNDAOptionRT = Union(
  Literal("notRequired"),
  Literal("required"),
  Literal("alreadyOnFile"),
);
export type RequireNDAOption = Static<typeof RequireNDAOptionRT>;
export const REQUIRE_NDA_OPTIONS: RequireNDAOption[] =
  RequireNDAOptionRT.alternatives.map((v) => v.value);

export const PrivateDataRoomLinkRT = Record({
  id: PrivateDataRoomLinkIdRT,
  type: Literal("customerAssurancePrivateDataRoomLink"),
  attributes: Record({
    recipientEmails: Array(String),
    ccEmails: Array(String),
    bccEmails: Array(String),
    emailDomains: Array(String),
    ndaRequired: RequireNDAOptionRT,
    expiresAt: ISODateStrRT,
    createdAt: ISODateStrRT,
    link: String,
    includeProfile: Boolean,
    isNdaDownloadable: Boolean,
  }),
  relationships: Record({
    documents: Array(CustomerAssuranceDocumentRT),
    profileVersion: ProfileVersionRT,
  }),
});
export type PrivateDataRoomLink = Static<typeof PrivateDataRoomLinkRT>;

export const DocusignEnvelopeRT = Record({
  envelopeId: String,
  recipientEmail: String,
  recipientName: String,
  status: String,
});
export type DocusignEnvelope = Static<typeof DocusignEnvelopeRT>;

export enum SortDirections {
  ascending = "ascending",
  descending = "descending",
}

export type GroupedDocuments = {
  label: React.ReactNode;

  options: {
    label: string;
    value: string;
  }[];
}[];

export const NdaStatusRT = Record({
  ndaRequired: Boolean,
  ndaSigned: Boolean,
  isNdaDownloadable: Boolean,
  nda: NdaRT.nullable(),
});

export type NdaStatus = Static<typeof NdaStatusRT>;

export type FilterType = "viewAll" | "privateDocuments" | "publicDocuments";

export const UNGROUPED_GROUP: DocumentGroup = {
  id: "ungrouped" as DocumentGroupId,
  type: "customerAssuranceDocumentGroup",
  attributes: {
    title: "Uncategorized",
    description: "Documents without an assigned group",
  },
};
