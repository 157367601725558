import { InlineProductSelector } from "../../components/ActiveProductSelector";
import AppHeader from "../../components/AppHeader";
import { useGetMeQuery } from "../../features/API/auth";

const AnalyticsContainer = (): JSX.Element => {
  const { data: meData } = useGetMeQuery();
  const isStaff = meData?.user.attributes.isStaff;

  return (
    <>
      {isStaff && (
        <AppHeader title="Analytics" extra={<InlineProductSelector />} />
      )}
    </>
  );
};

export default AnalyticsContainer;
