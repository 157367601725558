import {
  Boolean,
  Dictionary,
  Literal,
  Record,
  Static,
  String,
  Union,
} from "runtypes";
import { TokenDataRT } from "shared_utils/tokenHandler";
import { ISODateStrRT, UserRT } from "../API/types";

const AnalyticsUuidRT = String.withBrand("AnalyticsUUID");
export type AnalyticsUUID = Static<typeof AnalyticsUuidRT>;

const UserDataRT = Record({
  user: UserRT,
  analyticsUUID: AnalyticsUuidRT,
  authProvider: String,
  isEnrolledInMFA: Boolean,
  createdAt: ISODateStrRT,
  knownFeatureFlags: Dictionary(Boolean),
});

export type UserData = Static<typeof UserDataRT>;

const UserAuthDataPartialRT = Record({
  authResultType: Literal("needs_mfa"),
  email: String,
  name: String,
  isVerified: Boolean,
  isActive: Boolean,
  partialAuthToken: String,
  isEnrolledInMFA: Boolean,
});
export type UserAuthDataPartial = Static<typeof UserAuthDataPartialRT>;

const UserAuthDataFullRT = Record({
  authResultType: Literal("full"),
  email: String,
  name: String,
  isVerified: Boolean,
  isActive: Boolean,
  isEnrolledInMFA: Boolean,
  tokens: TokenDataRT,
});

const UserAuthDataRegistrationRT = Record({
  authResultType: Literal("registration"),
  email: String,
  name: String,
  isVerified: Boolean,
  isActive: Boolean,
});

export const UserAuthDataRT = Union(
  UserAuthDataFullRT,
  UserAuthDataPartialRT,
  UserAuthDataRegistrationRT,
);

export type UserAuthData = Static<typeof UserAuthDataRT>;
