import type { Dayjs } from "dayjs";
import saveAs from "file-saver";
import type { ValidatorRule } from "rc-field-form/lib/interface";
import { useCallback } from "react";
import { ensureExhaustive } from "shared_frontend/src/utils";
import {
  downloadFileFromSignedUrl,
  getFileContentAsBlobFromSignedUrl,
} from "shared_frontend/src/utils/helper";
import TokenHandler from "shared_utils/tokenHandler";
import MagicLinkTokenHandler from "../containers/PrivateDataRoomFlowContainer/token";
import { useGetMeQuery } from "../features/API/auth";
import {
  CustomerRBACRole,
  type ISODateStr,
  InternalRole,
  type RBACRole,
  type User,
} from "../features/API/types";
import { ProfileVersionResponse } from "../features/AssuranceProfile/autoGeneratedTypes";
import { APIEndpoints } from "./constants";
import { magicLinkUncachedFetch, uncachedFetch } from "./request";

// regex to match emails with display names
export const EMAIL_WITH_NAME_REGEX = /(.*)\s<([^>]+)>/g;

// Like isValidEmail but allows for emails with names like
// "John Doe <john@example.com>"
export const isValidEmailWithName = (inputText: string): boolean => {
  const match = EMAIL_WITH_NAME_REGEX.exec(inputText);
  return match ? isValidEmail(match[2]) : isValidEmail(inputText);
};

export const isValidEmail = (inputText: string): boolean => {
  // taken from: https://stackoverflow.com/a/46181
  const mailFormat =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return !!inputText.match(mailFormat);
};

export const isValidDomain = (inputText: string): boolean => {
  const domainFormat = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

  return !!inputText.match(domainFormat);
};

export const isValidWebsite = (inputText: string): boolean => {
  const domainFormat = /^[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  try {
    const url = new URL(inputText);
    return (
      (url.protocol === "http:" || url.protocol === "https:") &&
      !!url.hostname.match(domainFormat)
    );
  } catch (_) {
    return !!inputText.match(domainFormat);
  }
};

export const validateEmail = (
  inputText: string,
  setError: (_: string) => void,
): boolean => {
  if (isValidEmail(inputText)) {
    return true;
  }
  setError("Please enter valid email address.");
  return false;
};

export const validatePassword = (
  inputText: string,
  setError: (_: string) => void,
): boolean => {
  if (inputText.length < 8) {
    setError("Password length must be at least 8 characters.");
    return false;
  }
  const passwordFormat = "(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])";
  if (!inputText.match(passwordFormat)) {
    setError(
      "Password must contain at least one uppercase character, one lowercase character, and one number.",
    );
    return false;
  }
  return true;
};

export const validateConfirmPassword = (
  inputText1: string,
  inputText2: string,
  setError: (_: string) => void,
): boolean => {
  if (!validatePassword(inputText1, setError)) {
    return false;
  }

  if (inputText1 !== inputText2) {
    setError("Passwords do not match.");
    return false;
  }

  return true;
};

export const validateAgainstEmptyResults = (value: unknown): boolean => {
  if (!value) {
    return false;
  }
  if (typeof value === "string") {
    return value.trim() !== "";
  }
  if (Array.isArray(value)) {
    return value.length > 0;
  }
  return true;
};

export const validateAgainstEmptyHtmlResults = (value: unknown): boolean => {
  if (!value) {
    return false;
  }
  if (typeof value === "string") {
    const cleanValue = value.replace(/<[^>]*>/g, "");
    return cleanValue.trim() !== "";
  }
  if (Array.isArray(value)) {
    return value.length > 0;
  }
  return true;
};

export const createValidatorRule = (
  test: (value: unknown) => boolean,
  errorMessage: string,
): ValidatorRule => {
  return {
    validator(_, value: unknown) {
      if (!test(value)) {
        return Promise.reject(new Error(errorMessage));
      }
      return Promise.resolve();
    },
  };
};

export const convertDateFormat = (date?: Dayjs | null): string | null => {
  if (date === null || date === undefined) {
    return null;
  }
  const month = ("0" + (date.month() + 1)).slice(-2);
  const day = ("0" + date.date()).slice(-2);
  return [month, day, date.year()].join("/");
};

export const isDateExpired = (expirationDate: ISODateStr): boolean => {
  const now = new Date();
  const expiryTime = new Date(expirationDate);
  return now > expiryTime;
};

export const titleCase = (str: string): string => {
  if (str === "") {
    return str;
  }
  return str[0].toUpperCase() + str.slice(1);
};

export function groupBy<T, S extends string | number | symbol>(
  arr: T[],
  callable: { (callee: T): S },
): Record<S, T[]> {
  const grouped = {} as Record<S, T[]>;
  arr.forEach((item) => {
    const groupKey = callable(item);
    if (grouped[groupKey]) {
      grouped[groupKey].push(item);
    } else {
      grouped[groupKey] = [item];
    }
  });
  return grouped;
}

export const setDifference = <T>(a: Set<T>, b: Set<T>): Set<T> =>
  new Set(Array.from(a).filter((x) => !b.has(x)));

export enum SocketType {
  mail = "mail",
  presence = "presence",
  docusign = "docusign",
  general = "general",
}

export const getSocketUrl = async (socketType: SocketType): Promise<string> => {
  if (socketType === SocketType.docusign) {
    throw new Error("Use getDocusignSocketUrl for Docusign socket type");
  }

  let token: string | null = null;
  while (!token) {
    token = await TokenHandler.getAccessToken();
    if (!token) {
      await new Promise((r) => setTimeout(r, 5000));
    }
  }
  if (socketType === SocketType.general) {
    return `${window.ENV_VARIABLE_BACKEND_SOCKET_BASE_URL ?? ""}/?token=${token}`;
  }

  return `${window.ENV_VARIABLE_BACKEND_SOCKET_BASE_URL ?? ""}/${socketType}/?token=${token}`;
};

export const getDocusignSocketUrl = async (
  envelope_id: string,
  isPublicDataRoom: boolean = false,
): Promise<string> => {
  let token: string | null = null;
  while (!token) {
    if (isPublicDataRoom) {
      token = await TokenHandler.getAccessToken();
      if (!token) {
        await new Promise((r) => setTimeout(r, 5000));
      }
    } else {
      const authToken = MagicLinkTokenHandler.loadToken();
      if (authToken && !MagicLinkTokenHandler.isTokenExpired()) {
        token = authToken.accessToken;
      }
    }
  }
  return `${window.ENV_VARIABLE_BACKEND_SOCKET_BASE_URL ?? ""}/docusign/?token=${token}&envelope_id=${envelope_id}`;
};

export const renderRole = (role: RBACRole): string => {
  switch (role) {
    case CustomerRBACRole.APP_ADMIN:
      return "Admin";
    case CustomerRBACRole.APP_EDITOR:
      return "Editor";
    case CustomerRBACRole.APP_VIEWER:
      return "Viewer";
    case CustomerRBACRole.CONCIERGE_DASHBOARD_USER:
      return "Concierge Dashboard User";
    case InternalRole.SP_EMPLOYEE:
      return "SecurityPal Employee";
    case InternalRole.SP_LIBRARY_MAINTAINER:
      return "SecurityPal Library Maintainer";
    case InternalRole.SP_ONBOARDER:
      return "SecurityPal Onboarder";
    case InternalRole.SP_DELIVERY_POD_LEADER:
      return "SecurityPal Delivery Pod Leader";
    case InternalRole.SP_MISSION_CONTROL_SENDER:
      return "SecurityPal Mission Control Sender";
    case InternalRole.SP_CUSTOMER_SUCCESS:
      return "SecurityPal Customer Success";
    case InternalRole.SP_CUSTOMER_ASSURANCE_EDITOR:
      return "Assurance Profile Editor";
    case InternalRole.SP_CUSTOMER_ASSURANCE_PUBLISHER:
      return "Assurance Profile Publisher";
    case InternalRole.SP_ENG:
      return "SecurityPal Engineering";
    case InternalRole.SP_FINAL_REVIEWERS:
      return "SecurityPal Final Reviewers";
    case InternalRole.SP_INITIAL_REVIEWERS:
      return "SecurityPal Initial Reviewers";
    case InternalRole.SP_CIRRT:
      return "SecurityPal CIRRT";
    case InternalRole.SP_LEADS_AND_MANAGERS:
      return "SecurityPal Leads And Managers";
    case InternalRole.SP_KL_FINAL_REVIEWERS:
      return "SecurityPal KL Final Reviewers";
    case InternalRole.SP_KL_INITIAL_REVIEWERS:
      return "SecurityPal KL Initial Reviewers";
    case InternalRole.SP_KL_LEAD:
      return "SecurityPal KL Lead";
    case InternalRole.SP_DATA_TEAM:
      return "SecurityPal Data Team";
    case InternalRole.SP_VENDOR_ASSESS_VIEWER:
      return "SecurityPal Vendor Assess Viewers";
    case InternalRole.SP_VENDOR_ASSESS_EDITOR:
      return "SecurityPal Vendor Assess Editors";
    default:
      return ensureExhaustive(role);
  }
};

export const downloadFile = async (
  url: string,
  magicLinkAuthed?: boolean,
): Promise<Blob> => {
  const fetchFunc = magicLinkAuthed ? magicLinkUncachedFetch : uncachedFetch;
  const r = await fetchFunc(url);
  if (!r.ok) {
    throw new Error(`Failed to download file: ${r.statusText}`);
  }
  return r.blob();
};

interface FirstAndLastNames {
  first?: string;
  last?: string;
}
/**
 * Attempts to split a name into the first and last name based on whitespace.
 * Assumptions:
 * - if there is only one "words", we assume that is a first name
 * - if there are more than two "words", we assume the first is the
 *   first name and the rest make up the last name
 */
export const splitNameIntoFirstAndLast = (name: string): FirstAndLastNames => {
  const splits = name.trim().split(" ");
  if (splits.length === 0) {
    return {};
  } else if (splits.length === 1) {
    return {
      first: splits[0],
    };
  } else {
    const first = splits[0];
    const last = splits.slice(1).join(" ");
    return { first, last };
  }
};

//on the backend, before sending, we replace empty tags like <div></div> with <div><br /></div>.
//we need to replicate this for scheduled drafts so the preview looks the same as the sent email
export const replaceEmptyTags = (html: string): string => {
  [1, 2, 3, 4, 5, 6].forEach((i) => {
    html = html.replace(
      new RegExp(`<h${i}></h${i}>`, "g"),
      `<h${i}><br /></h${i}>`,
    );
  });
  return html.replace(/<div><\/div>/g, "<div><br /></div>");
};

export const hasGlobalRole = (
  user: User | undefined,
  role: CustomerRBACRole | InternalRole,
) => {
  return user?.relationships?.accessGroups?.some(
    (ag) => ag.attributes.role === role,
  );
};

export const userAuthBulkDownloadPublicDocuments = async (
  profileVersion: ProfileVersionResponse,
  documentIds: string[],
) => {
  const params = new URLSearchParams();
  documentIds.forEach((documentId) => {
    params.append("documentIds", documentId);
  });
  params.append("companyName", profileVersion.attributes.companyName);
  const blob = await downloadFile(
    `${APIEndpoints.customerAssurance.documents.USER_AUTH_BULK_DOWNLOAD_CUSTOMER_ASSURANCE_DOCUMENTS}?${params}`,
  );
  saveAs(blob, `${profileVersion.attributes.companyName}.zip`);
};

export const downloadViaSignedUrl = (url: string, fileName?: string) => {
  return downloadFileFromSignedUrl(url, fileName, uncachedFetch);
};

export const getContentViaSignedUrl = async (
  url: string,
  magicLinkAuthed?: boolean,
): Promise<Blob> => {
  const fetchFunc = magicLinkAuthed ? magicLinkUncachedFetch : uncachedFetch;
  return getFileContentAsBlobFromSignedUrl(url, fetchFunc);
};
export const uploadFile = async (
  signedUploadUrl: string,
  file: File,
): Promise<Response> => {
  const r = await fetch(signedUploadUrl, {
    method: "PUT",
    body: file,
    headers: {
      "Content-Type": "application/octet-stream",
    },
  });
  if (!r.ok) {
    throw new Error(`Failed to upload file: ${r.statusText}`);
  }
  return r;
};
export const useDownloadFile = () => {
  const { data: meData } = useGetMeQuery();
  const robustDownloadEnabled =
    meData?.knownFeatureFlags["robust-download-enabled"];

  const download = useCallback(
    async (
      url: string,
      fileName?: string,
      magicLinkAuthed?: boolean,
    ): Promise<void> => {
      try {
        if (robustDownloadEnabled) {
          await downloadViaSignedUrl(url);
        } else {
          const blob = await downloadFile(url, magicLinkAuthed);
          saveAs(blob, fileName);
        }
      } catch (error) {
        throw new Error(
          `Failed to download file: ${error instanceof Error ? error.message : error}`,
        );
      }
    },
    [robustDownloadEnabled],
  );

  const getFileContent = useCallback(
    async (url: string, magicLinkAuthed?: boolean): Promise<Blob> => {
      try {
        if (robustDownloadEnabled) {
          return await getContentViaSignedUrl(url, magicLinkAuthed);
        } else {
          return await downloadFile(url, magicLinkAuthed);
        }
      } catch (error) {
        throw new Error(
          `Failed to fetch file content : ${error instanceof Error ? error.message : error}`,
        );
      }
    },
    [robustDownloadEnabled],
  );

  return { download, getFileContent };
};

export const uploadFilesToGCS = async (
  files: File[],
  signedUrls: { uploadUrl: string; gcsFileName: string }[],
): Promise<void[]> => {
  const uploadPromises = files.map(async (file, index) => {
    const { uploadUrl } = signedUrls[index];
    await uploadFile(uploadUrl, file);
  });

  return Promise.all(uploadPromises);
};
