import {
  EditOutlined,
  PlusOutlined,
  SecurityScanOutlined,
} from "@ant-design/icons";
import { Button, ModalProps } from "antd";
import { useState } from "react";
import { CertificationSelectionResponse } from "../../../app_frontend/src/features/AssuranceProfile/autoGeneratedTypes";
import { RankDirection } from "../../../app_frontend/src/features/AssuranceProfile/generatedTypes/tileTypes";
import AssuranceProfileCard from "../AssuranceProfileCard";
import CardHeader from "../CardHeader";
import styles from "./CertificationSelections.module.scss";
import CertificationSelectionsList from "./CertificationSelectionsList";
import ViewAllCertificationsModal from "./ViewAllCertificationsModal";
interface CertificationSelectionsProps {
  selections: CertificationSelectionResponse[];
  CreateModal?: React.FC<ModalProps>;
  EditModal?: React.FC<ModalProps>;
  onMoveCertificationSelection?: (
    certificationSelectionId: string,
    direction: RankDirection,
  ) => void;
}

const MAX_CERTIFICATION_IN_TILE = 6;

const CertificationSelections = ({
  selections,
  CreateModal,
  EditModal,
  onMoveCertificationSelection,
}: CertificationSelectionsProps): JSX.Element => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  return (
    <>
      <AssuranceProfileCard className={styles.Card}>
        <CardHeader
          icon={<SecurityScanOutlined />}
          title="Compliance and Certifications"
          titleLevel={2}
          editButton={
            <div className={styles.ActionButtons}>
              {EditModal && (
                <Button
                  className={styles.EditButton}
                  icon={<EditOutlined />}
                  onClick={() => setEditModalOpen(true)}
                />
              )}
              {CreateModal && (
                <Button
                  icon={<PlusOutlined />}
                  onClick={() => setCreateModalOpen(true)}
                />
              )}
            </div>
          }
        />
        <CertificationSelectionsList
          certificationSelections={
            selections.length > MAX_CERTIFICATION_IN_TILE
              ? selections.slice(0, MAX_CERTIFICATION_IN_TILE)
              : selections
          }
          CreateModal={CreateModal}
          onMoveCertificationSelection={onMoveCertificationSelection}
          strippedCertifications={selections.length > MAX_CERTIFICATION_IN_TILE}
        />
        {selections.length > MAX_CERTIFICATION_IN_TILE && (
          <ViewAllCertificationsModal
            certificationSelections={selections}
            CreateModal={CreateModal}
            onMoveCertificationSelection={onMoveCertificationSelection}
          />
        )}
      </AssuranceProfileCard>

      {CreateModal && (
        <CreateModal
          open={createModalOpen}
          onOk={() => setCreateModalOpen(false)}
          onCancel={() => setCreateModalOpen(false)}
        />
      )}
      {EditModal && (
        <EditModal
          open={editModalOpen}
          onOk={() => setEditModalOpen(false)}
          onCancel={() => setEditModalOpen(false)}
        />
      )}
    </>
  );
};

export default CertificationSelections;
