import {
  Array,
  Boolean,
  Literal,
  Number,
  Optional,
  Record,
  Static,
  String,
  Union,
} from "runtypes";
import { ProfileIdRT, ProfileVersionIdRT } from "shared_frontend";
import {
  CustomerAssuranceDocumentRT,
  SortDirections,
} from "shared_frontend/src/types/assuranceProfile";
import { ISODateStrRT } from "../API/types";

const InformationRequestIdRT = String.withBrand("InformationRequestId");
export type InformationRequestId = Static<typeof InformationRequestIdRT>;
const DataRoomLinkIdRT = String.withBrand("DataRoomLinkId");
export type DataRoomLinkId = Static<typeof DataRoomLinkIdRT>;

const MagicLinkIdRT = String.withBrand("MagicLinkId");
export type MagicLinkId = Static<typeof MagicLinkIdRT>;

const DataRoomEventIdRT = String.withBrand("DataRoomEventId");
export type DataRoomEventId = Static<typeof DataRoomEventIdRT>;

const InformationRequestStatusRT = Union(
  Literal("pending"),
  Literal("approved"),
  Literal("rejected"),
);

const DocusignEnvelopeStatusRT = Union(
  Literal("declined"),
  Literal("delivered"),
  Literal("sent"),
  Literal("completed"),
);

export type InformationRequestStatus = Static<
  typeof InformationRequestStatusRT
>;
export type DocusignEnvelopeStatus = Static<typeof DocusignEnvelopeStatusRT>;

export const RelationshipToCompanyOptionRT = Union(
  Literal("auditor"),
  Literal("currentCustomer"),
  Literal("currentPartner"),
  Literal("potentialCustomer"),
  Literal("potentialPartner"),
  Literal("vendor"),
  Literal("other"),
);
export type RelationshipToCompanyOption = Static<
  typeof RelationshipToCompanyOptionRT
>;

export const InformationRequestRT = Record({
  id: InformationRequestIdRT,
  type: Literal("customerAssuranceInformationRequest"),
  attributes: Record({
    profileId: ProfileIdRT,
    profileVersionId: ProfileVersionIdRT,
    profileName: String,
    recipientEmailAddresses: Array(String),
    firstName: String.nullable(),
    lastName: String.nullable(),
    email: String,
    companyName: String,
    status: InformationRequestStatusRT,
    comment: String.nullable(),
    rejectionNotes: String.nullable(),
    relationshipToCompany: RelationshipToCompanyOptionRT.nullable(),
    hasCustomQuestionnaire: Boolean,
    ndaOnFile: Boolean,
    createdAt: ISODateStrRT,
    updatedAt: ISODateStrRT,
    approvedBy: String.nullable(),
    rejectedBy: String.nullable(),
    approvedAt: ISODateStrRT.nullable(),
    rejectedAt: ISODateStrRT.nullable(),
  }),
  relationships: Record({
    documents: Array(CustomerAssuranceDocumentRT),
    docusignEnvelopeStatus: DocusignEnvelopeStatusRT.nullable(),
  }),
});
export type InformationRequest = Static<typeof InformationRequestRT>;

export const DataRoomLinkRT = Record({
  id: ProfileIdRT,
  type: Literal("customerAssuranceProfile"),
  attributes: Record({
    informationRequestId: InformationRequestIdRT,
    expiresAt: ISODateStrRT,
  }),
});
export type DataRoomLink = Static<typeof DataRoomLinkRT>;

const DataRoomEventTypeRT = Union(
  Literal("documentEvent"),
  Literal("roomAccessed"),
  Literal("ndaSigned"),
  Literal("ndaSent"),
);
export type DataRoomEventType = Static<typeof DataRoomEventTypeRT>;

export const DataRoomEventRT = Record({
  id: DataRoomEventIdRT,
  type: DataRoomEventTypeRT,
  attributes: Record({
    createdAt: ISODateStrRT,
    accessorEmail: String,
  }),
  relationships: Optional(
    Record({
      document: CustomerAssuranceDocumentRT,
    }),
  ),
});
export type DataRoomEvent = Static<typeof DataRoomEventRT>;

export const MagicLinkAuthTokenRT = Record({
  accessToken: String,
});
export type MagicLinkAuthToken = Static<typeof MagicLinkAuthTokenRT>;

export type PrivateDataRoomLinksFilter = {
  page?: number;
  limit?: number;
  sortOrder?: SortDirections;
  sortField?: string;
};

export type DataRoomEventsFilters = {
  sortDirection?: SortDirections | null;
  page?: number | null;
  limit?: number | null;
};

export const ProfileUsageStatsRT = Record({
  totalPageVisits: Number,
  totalDocumentDownloads: Number,
  totalInformationRequests: Number,
});

export type ProfileUsageStats = Static<typeof ProfileUsageStatsRT>;
