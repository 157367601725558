import { EditOutlined } from "@ant-design/icons";
import { Button, ModalProps, Typography } from "antd";
import { useState } from "react";
import SanitizedHTML from "../SanitizedHTML/SanitizedHTML";
import styles from "./RequestInfoSubtitle.module.scss";

interface RequestInfoSubtitleProps {
  subtitle?: string | null;
  EditModal?: React.FC<ModalProps>;
}
const RequestInfoSubtitle = ({
  subtitle,
  EditModal,
}: RequestInfoSubtitleProps): JSX.Element => {
  const [editModalOpen, setEditModalOpen] = useState(false);

  if (!subtitle && !EditModal) {
    return <></>;
  }

  return (
    <>
      {subtitle ? (
        <div className={styles.Subtitle}>
          <Typography.Text>
            <SanitizedHTML html={subtitle} />
          </Typography.Text>
          {EditModal && (
            <Button
              onClick={() => setEditModalOpen(true)}
              size="small"
              icon={<EditOutlined />}
              type="text"
              className={styles.EditSubtitleButton}
            />
          )}
        </div>
      ) : (
        <Button
          className={styles.AddSubtitleButton}
          onClick={() => setEditModalOpen(true)}
        >
          Add a subtitle
        </Button>
      )}
      {EditModal && (
        <EditModal
          open={editModalOpen}
          onCancel={() => setEditModalOpen(false)}
          onOk={() => setEditModalOpen(false)}
        />
      )}
    </>
  );
};

export default RequestInfoSubtitle;
