import { IroncladNdaResponse } from "../../../app_frontend/src/features/AssuranceProfile/autoGeneratedTypes";

export type IroncladCheckLatestData = Record<number, boolean>;

export const checkNdaAlreadySigned = async (
  email: string,
  nda: IroncladNdaResponse,
): Promise<boolean> => {
  const isProduction = window.ENV_VARIABLE_SENTRY_ENVIRONMENT === "prod";

  const IRONCLAD_URL_PREFIX = isProduction
    ? "https://pactsafe.io/latest"
    : "https://demo.pactsafe.io/latest";

  const escapedEmail = encodeURIComponent(email);
  const url = `${IRONCLAD_URL_PREFIX}?sid=${nda.attributes.siteAccessId}&gkey=${nda.attributes.groupKey}&sig=${escapedEmail}`;
  const ironcladResponse = await fetch(url);
  const ironcladData =
    (await ironcladResponse.json()) as IroncladCheckLatestData;

  return Object.values(ironcladData).every(Boolean);
};
