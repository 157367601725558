import { Typography } from "antd";
import { cloneElement } from "react";
import styles from "./CardHeader.module.scss";

interface CardHeaderProps {
  icon?: JSX.Element;
  titleLevel?: 2 | 5;
  title: string;
  editButton?: JSX.Element;
  titleClassName?: string;
}

const CardHeader = ({
  icon,
  titleLevel,
  title,
  editButton,
  titleClassName,
}: CardHeaderProps): JSX.Element => {
  return (
    <div className={`${styles.Header} TileHeader`}>
      <div className={styles.Title}>
        {icon && cloneElement(icon, { className: "TileIcon" })}
        {titleLevel ? (
          <Typography.Title
            level={titleLevel}
            className={`${titleClassName} TileTitle`}
          >
            {title}
          </Typography.Title>
        ) : (
          <Typography.Text
            strong
            className={`${styles.TileTitle_NonHeading} TileTitle`}
          >
            {title}
          </Typography.Text>
        )}
      </div>
      {editButton}
    </div>
  );
};

export default CardHeader;
