import { DownloadOutlined, UnlockFilled } from "@ant-design/icons";
import { Col, Row, Typography } from "antd";
import { useState } from "react";
import { AssuranceDocumentResponse } from "../../../app_frontend/src/features/AssuranceProfile/autoGeneratedTypes";
import styles from "./DocumentCards.module.scss";

interface DocumentCardsProps {
  customerAssuranceDocuments: AssuranceDocumentResponse[];
  onDownloadPublicDocument?: (document: AssuranceDocumentResponse) => void;
}

const DocumentCards: React.FC<DocumentCardsProps> = ({
  customerAssuranceDocuments,
  onDownloadPublicDocument,
}) => {
  const [hoveredCardId, setHoveredCardId] = useState<string | null>(null);

  return (
    <Row gutter={[24, 24]}>
      {customerAssuranceDocuments.map((document: AssuranceDocumentResponse) => (
        <Col key={document.id} xs={12} sm={8} md={6} lg={4} xl={3}>
          <div
            className={styles.DocumentCard}
            onMouseEnter={() => setHoveredCardId(document.id)}
            onMouseLeave={() => setHoveredCardId(null)}
          >
            <div className={styles.DocumentCardBody}>
              <Typography.Paragraph
                ellipsis={{
                  rows: 3,
                }}
                className="TileTitle"
              >
                {document.attributes.title}
              </Typography.Paragraph>
              {document.attributes.isPublic &&
                (hoveredCardId === document.id ? (
                  <Row
                    className={styles.DocumentCardIconAndText}
                    onClick={(event) => {
                      onDownloadPublicDocument?.(document);
                      event.preventDefault();
                    }}
                  >
                    <DownloadOutlined
                      className={styles.DocumentCardBodyDownloadIcon}
                    />
                    <span className={styles.DocumentCardIconText}>
                      Download
                    </span>
                  </Row>
                ) : (
                  <UnlockFilled className={styles.DocumentCardBodyIcon} />
                ))}
            </div>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default DocumentCards;
