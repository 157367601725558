import { memo, useState } from "react";
import {
  PiCompassLight,
  PiFileLight,
  PiToggleRightLight,
} from "react-icons/pi";
import { Link } from "react-router-dom";
import { ProfileVersionResponse } from "../../../app_frontend/src/features/AssuranceProfile/autoGeneratedTypes";
import styles from "./NavBarV2.module.scss";

export type NavBarMenuItem = keyof typeof NavBarMenuItems;

export enum NavBarMenuItems {
  Overview = "Overview",
  Controls = "Controls",
  Documents = "Documents",
}

interface MenuItemProps {
  menuItem: NavBarMenuItem;
  isSelected: boolean;
  accentColor: string;
  variant: "desktop" | "mobile";
  onClick: () => void;
}

/**
 Build a URl path by adding subdomain to query param if it already exists in current URL.
 */
export const buildPath = ({
  path,
  hasAdditionalParam,
}: { path: string; hasAdditionalParam?: boolean }) => {
  const searchParams = new URLSearchParams(location.search);
  if (!searchParams.has("subdomain")) return path;

  const separator = hasAdditionalParam ? "&" : "?";
  return `${path}${separator}subdomain=${searchParams.get("subdomain")}`;
};

export const MENU_ITEMS_CONFIG = {
  [NavBarMenuItems.Overview]: {
    icon: PiCompassLight,
    path: buildPath({ path: "./" }),
    state: { scrollToOverview: true },
  },
  [NavBarMenuItems.Controls]: {
    icon: PiToggleRightLight,
    path: buildPath({ path: "./controls" }),
  },
  [NavBarMenuItems.Documents]: {
    icon: PiFileLight,
    path: buildPath({ path: "./documents" }),
  },
} as const;

export const filterMenuItems = (
  menuItems: NavBarMenuItem[],
  profileVersionRelationships: ProfileVersionResponse["relationships"],
) => {
  return menuItems.filter((item) => {
    if (
      !profileVersionRelationships.tiles.length &&
      item === NavBarMenuItems.Controls
    )
      return false;
    if (
      !profileVersionRelationships.documents.length &&
      item === NavBarMenuItems.Documents
    )
      return false;
    return true;
  });
};

export const NavMenuItem = memo(
  ({ menuItem, isSelected, accentColor, variant, onClick }: MenuItemProps) => {
    const [isHovered, setIsHovered] = useState(false);
    const config = MENU_ITEMS_CONFIG[menuItem];
    const Icon = config.icon;
    const containerClass =
      variant === "desktop"
        ? styles.NavLinkContainer
        : styles.HamburgerNavLinkContainer;
    const iconClass =
      variant === "desktop" ? styles.NavLinkLogo : styles.HamburgerNavLinkLogo;

    return (
      <Link
        to={config.path}
        state={"state" in config ? config.state : undefined}
        aria-current={isSelected ? "page" : undefined}
        style={{
          textDecoration: "none",
          color: isSelected ? accentColor : "inherit",
        }}
        className={styles.NavMenuLink}
        onClick={onClick}
      >
        <div
          className={containerClass}
          style={{
            borderBottomColor:
              isSelected || isHovered ? accentColor : "transparent",
            backgroundColor: isSelected ? "#F9FAFB" : undefined,
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          role="menuitem"
          tabIndex={0}
        >
          <Icon
            className={iconClass}
            style={{
              color: isSelected ? accentColor : undefined,
            }}
          />
          <div
            style={{
              color: isSelected ? accentColor : undefined,
            }}
          >
            {menuItem}
          </div>
        </div>
      </Link>
    );
  },
);

NavMenuItem.displayName = "NavMenuItem";
