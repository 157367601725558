import {
  EditOutlined,
  MoreOutlined,
  PlusOutlined,
  TableOutlined,
} from "@ant-design/icons";
import { IconName } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AppProps, Button, Col, Dropdown, ModalProps, Row, Space } from "antd";
import { useState } from "react";
import { TileResponse } from "../../../app_frontend/src/features/AssuranceProfile/autoGeneratedTypes";
import { RankDirection } from "../../../app_frontend/src/features/AssuranceProfile/generatedTypes/tileTypes";
import AssuranceProfileCard from "../AssuranceProfileCard";
import {
  CreateTileItemModalProps,
  DeleteTileItemPopconfirmProps,
  DeleteTilePopconfirmProps,
  EditTileItemModalProps,
  EditTileModalProps,
} from "../AssuranceProfileWrapper";
import CardHeader from "../CardHeader";
import MoveRankButtons from "../MoveRankButtons/MoveRankButtons";
import TileItems from "../TileItems";
import styles from "./Tiles.module.scss";

interface EditorComponents {
  CreateTileModal: React.FC<ModalProps>;
  EditTileModal: React.FC<EditTileModalProps>;
  CreateTileItemModal: React.FC<CreateTileItemModalProps>;
  EditTileItemModal: React.FC<EditTileItemModalProps>;
  DeleteTileItemPopconfirm: React.FC<DeleteTileItemPopconfirmProps>;
  DeleteTilePopconfirm: React.FC<DeleteTilePopconfirmProps>;
}

interface TileProps extends AppProps {
  tiles: TileResponse[];
  editorComponents?: EditorComponents;
  onMoveTile?: (tileId: string, direction: RankDirection) => void;
  onMoveTileItem?: (tileItemId: string, direction: RankDirection) => void;
}

const Tiles = ({
  tiles,
  editorComponents,
  onMoveTile,
  onMoveTileItem,
  ...rest
}: TileProps): JSX.Element => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentTileId, setCurrentTileId] = useState<string>();

  const CreateTileModal = editorComponents?.CreateTileModal;
  const EditTileModal = editorComponents?.EditTileModal;
  const CreateTileItemModal = editorComponents?.CreateTileItemModal;
  const EditTileItemModal = editorComponents?.EditTileItemModal;
  const DeleteTileItemPopconfirm = editorComponents?.DeleteTileItemPopconfirm;
  const DeleteTilePopconfirm = editorComponents?.DeleteTilePopconfirm;

  const dropDownItems = [
    {
      key: "edit",
      label: (
        <Space onClick={() => setEditModalOpen(true)}>
          <EditOutlined /> Edit
        </Space>
      ),
    },
    {
      key: "delete",
      label: (
        <>
          {DeleteTilePopconfirm && currentTileId && (
            <DeleteTilePopconfirm tileId={currentTileId} />
          )}
        </>
      ),
    },
  ];

  const firstTile = tiles[0];
  const lastTile = tiles.slice(-1)[0];

  return (
    <>
      <AssuranceProfileCard {...rest}>
        {CreateTileModal && (
          <CardHeader
            icon={<TableOutlined />}
            title="Tiles"
            titleLevel={2}
            editButton={
              <Button
                onClick={() => setCreateModalOpen(true)}
                icon={<PlusOutlined />}
              />
            }
          />
        )}
        <Row gutter={[30, 30]}>
          {tiles.map((tile) => (
            <Col xs={24} md={12} lg={8} key={tile.id}>
              <AssuranceProfileCard className={styles.TileCard}>
                <CardHeader
                  titleLevel={2}
                  icon={
                    <FontAwesomeIcon
                      className={styles.TileIcon}
                      icon={tile.attributes.icon as IconName}
                    />
                  }
                  title={tile.attributes.header ?? ""}
                  editButton={
                    EditTileModal && (
                      <Dropdown
                        menu={{ items: dropDownItems }}
                        trigger={["click"]}
                        onOpenChange={() => setCurrentTileId(tile.id)}
                      >
                        <MoreOutlined />
                      </Dropdown>
                    )
                  }
                />

                <TileItems
                  tile={tile}
                  CreateModal={CreateTileItemModal}
                  EditModal={EditTileItemModal}
                  DeletePopConfirm={DeleteTileItemPopconfirm}
                  onMoveTileItem={onMoveTileItem}
                />

                {CreateTileModal && (
                  <MoveRankButtons
                    onMoveUp={() => onMoveTile?.(tile.id, "up")}
                    onMoveDown={() => onMoveTile?.(tile.id, "down")}
                    disableUp={tile.id === firstTile?.id}
                    disableDown={tile.id === lastTile?.id}
                    vertical={false}
                  />
                )}
              </AssuranceProfileCard>
            </Col>
          ))}
        </Row>
      </AssuranceProfileCard>

      {CreateTileModal && (
        <CreateTileModal
          open={createModalOpen}
          onCancel={() => setCreateModalOpen(false)}
          onOk={() => setCreateModalOpen(false)}
        />
      )}
      {EditTileModal && currentTileId && (
        <EditTileModal
          open={editModalOpen}
          onCancel={() => setEditModalOpen(false)}
          onOk={() => setEditModalOpen(false)}
          tileId={currentTileId}
        />
      )}
    </>
  );
};

export default Tiles;
