import {
  EditOutlined,
  MoreOutlined,
  PlusOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import {
  AppProps,
  Button,
  Col,
  Dropdown,
  ModalProps,
  Row,
  Space,
  Typography,
} from "antd";
import clsx from "clsx";
import { useState } from "react";
import { UpdateResponse } from "../../../app_frontend/src/features/AssuranceProfile/autoGeneratedTypes";
import AssuranceProfileCard from "../AssuranceProfileCard";
import {
  DeleteUpdatePopconfirmProps,
  EditUpdateModalProps,
} from "../AssuranceProfileWrapper";
import CardHeader from "../CardHeader";
import { ISODateStr } from "../types/assuranceProfile";
import { publishedDateString } from "../utils";
import styles from "./AssuranceProfileUpdates.module.scss";
interface UpdateProps extends AppProps {
  updates: UpdateResponse[];
  CreateModal?: React.FC<ModalProps>;
  EditModal?: React.FC<EditUpdateModalProps>;
  DeletePopconfirm?: React.FC<DeleteUpdatePopconfirmProps>;
}

const AssuranceProfileUpdates = ({
  updates,
  CreateModal,
  EditModal,
  DeletePopconfirm,
  ...rest
}: UpdateProps): JSX.Element => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [currentUpdateId, setCurrentUpdateId] = useState<string>();

  const dropDownItems = [
    {
      key: "edit",
      label: (
        <Space onClick={() => setEditModalOpen(true)}>
          <EditOutlined /> Edit
        </Space>
      ),
    },
    {
      key: "delete",
      label: (
        <>
          {DeletePopconfirm && currentUpdateId && (
            <DeletePopconfirm
              updateId={currentUpdateId}
              onConfirm={() => setCurrentUpdateId(undefined)}
            />
          )}
        </>
      ),
      disabled: !DeletePopconfirm,
    },
  ];

  return (
    <>
      <AssuranceProfileCard {...rest}>
        <CardHeader
          icon={<UnorderedListOutlined />}
          title="Assurance Profile Updates"
          titleLevel={2}
          editButton={
            CreateModal && (
              <Button
                onClick={() => setCreateModalOpen(true)}
                icon={<PlusOutlined />}
              />
            )
          }
        />
        <Row gutter={[30, 30]}>
          {updates.map((update, index) => (
            <Col xs={24} md={12} lg={8} key={index}>
              <AssuranceProfileCard>
                <CardHeader
                  icon={<></>}
                  title={update.attributes.header}
                  editButton={
                    EditModal && (
                      <Dropdown
                        menu={{ items: dropDownItems }}
                        trigger={["click"]}
                        onOpenChange={() => setCurrentUpdateId(update.id)}
                      >
                        <MoreOutlined />
                      </Dropdown>
                    )
                  }
                />
                <Typography.Paragraph className="TileText">
                  {update.attributes.body}
                </Typography.Paragraph>
                <Typography.Text
                  className={clsx(styles.PublishedDate, "TileText")}
                >
                  {publishedDateString(
                    update.attributes.publishedAt as ISODateStr,
                  )}
                </Typography.Text>
              </AssuranceProfileCard>
            </Col>
          ))}
        </Row>
      </AssuranceProfileCard>

      {CreateModal && (
        <CreateModal
          open={createModalOpen}
          onCancel={() => setCreateModalOpen(false)}
          onOk={() => setCreateModalOpen(false)}
        />
      )}
      {EditModal && currentUpdateId && (
        <EditModal
          open={editModalOpen}
          onCancel={() => setEditModalOpen(false)}
          onOk={() => setEditModalOpen(false)}
          updateId={currentUpdateId}
        />
      )}
    </>
  );
};

export default AssuranceProfileUpdates;
